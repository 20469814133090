import { IAppReducer } from 'models/reducer.interface';
import { setAuthenticatedUser } from 'pages/sing-in/store/actions/auth.types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../config/constants';
import { SettingsRouter } from './routes/Settings.routes';
import './Settings.scss';

export const Settings = () => {
  const user = useSelector((state: IAppReducer) => state.authReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user.authenticatedUser) {
      dispatch(setAuthenticatedUser(true));
      if (user.agentId) {
        window.location.reload();
      }
    }
  }, [user.authenticatedUser]);

  useEffect(() => {
    if (user.authenticatedUser) {
      try {
        const visitorData = {
          id: user.uid,
          email: user.email,
          firstName: user.name?.trim(),
          lastName: user.lastname?.trim(),
        };
        pendo.initialize({
          visitor: visitorData,
          account: {
            id: config.pendo.pendoAccountId,
          },
        });
      } catch (error) {
        console.error('Error al inicializar Pendo:', error);
      }
    }
  }, [user.authenticatedUser]);

  return (
    <section className="settings__container">
      <SettingsRouter />
    </section>
  );
};
