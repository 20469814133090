import { ItemCardSkeleton } from 'components';
import './Loading-skeleton.scss';

interface LoadingSkeletonProps {
  count: number;
}

export const LoadingSkeleton = ({ count }: LoadingSkeletonProps) => {
  return (
    <div className="loading-skeleton">
      {Array.from({ length: count }).map((_, index) => (
        <ItemCardSkeleton key={index} />
      ))}
    </div>
  );
};
