import { Images } from 'assets/images/images.index';
import { Button } from 'components/atoms/Button/Button';
import { Input } from 'components/atoms/Input/Input';
import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';
import { PopupEmailSend } from 'components/templates/Popup-email-send/Popup-email-send';
import { PopupErrorRecoverPassword } from 'components/templates/Popup-error-recover-password/Popup-error-recover-password';
import { FirebaseError } from 'firebase/app';
import { useFormik } from 'formik';
import { TypeState } from 'models/app.interface';
import { IDetailStep } from 'models/info.steps.interface';
import { IAppReducer } from 'models/reducer.interface';
import { setUser } from 'pages/sing-in/store/actions/auth.types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getPlanByCompanyId } from 'shared/services/plans';
import { impSales } from 'shared/services/user';
import { setBranch } from 'store/actions/branch.types';
import {
  IFormForgotPassword,
  IFormForgotPasswordErrors,
} from './models/Sales.interface';
import './Sales.scss';

export const Sales = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    authReducer: user,
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state);

  const [popUp, setPopUp] = useState<{
    isVisible: boolean;
    type: TypeState;
  }>({ isVisible: false, type: 'successful' });
  const formForgotPasswordInitialValues: IFormForgotPassword = {
    email: '',
  };
  const steps: IDetailStep[] = [
    {
      description: 'Cambio de cuenta ',
      state: 'active',
      link: '/forgot-password',
      permissionToNavigate: false,
    },
  ];
  const formForgotPasswordValidate = (values: IFormForgotPassword) => {
    const errors: IFormForgotPasswordErrors = {};
    if (!values.email) {
      errors.email = 'Este campo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Formato de correo electrónico incorrecto';
    }
    return errors;
  };
  const formForgotPassword = useFormik({
    initialValues: formForgotPasswordInitialValues,
    validate: formForgotPasswordValidate,
    onSubmit: async ({ email }) => {
      popUp.type = 'successful';
      try {
        const customRegister = await impSales(email);
        const response = await getPlanByCompanyId(
          customRegister.company.id.toString()
        );
        dispatch(
          setUser({
            userId: customRegister.id,
            uid: customRegister.auth_id,
            name: customRegister.name,
            lastname: customRegister.lastname,
            email: customRegister.email,
            accessToken: user.accessToken,
            userAuth: {
              phone: customRegister.phone,
              phone_country_code: customRegister.phone_country_code,
              country: {
                countryCode: customRegister.indicator,
              },
            },
            agentId: customRegister.agentId,
            companyId: customRegister.company.id,
            plansId: response.join(','),
            formToShow: customRegister.company?.formToShow || null,
            agentAuthData: {
              userId: user.userId,
              uid: user.uid,
              name: user.name,
              lastname: user.lastname,
              email: user.email,
              accessToken: user.accessToken,
              branch,
              userAuth: {
                phone: user.phone,
                phone_country_code: user.indicator,
              },
              plansId: user.plansId,
              companyId: user.companyId,
              role: user.role,
            },
          })
        );
        dispatch(setBranch(customRegister.country_id.acronym));
        navigate('/settings');
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          if (error.code === 'auth/user-not-found') {
            popUp.type = 'successful';
          } else {
            popUp.type = 'error';
          }
        } else {
          popUp.type = 'error';
        }
      } finally {
        setPopUp({ ...popUp, isVisible: true });
      }
    },
  });

  const showInfoPopUp = () => {
    setPopUp({ ...popUp, isVisible: !popUp.isVisible });
  };

  return (
    <>
      <HeaderWithSteps
        notProgress={true}
        steps={steps}
        onClick={() => {
          navigate('/settings/profile');
        }}
      />
      <form
        className="forgor__pasword__container container__generic"
        onSubmit={formForgotPassword.handleSubmit}
        noValidate
      >
        <h6>Ingrese el correo electrónico asociado a la cuenta.</h6>
        <p className="forgor__pasword__message">...</p>
        <Input
          label="Correo Electrónico"
          id="login-email"
          type="email"
          name="email"
          onChange={formForgotPassword.handleChange}
          onBlur={formForgotPassword.handleBlur}
          value={formForgotPassword.values.email}
          placeholder=""
          isTouched={formForgotPassword.touched.email}
          errorInput={formForgotPassword.errors.email}
          errorLabel={formForgotPassword.errors.email}
          isRequired={true}
          form={formForgotPassword}
        />
        <Button
          color="primary"
          size="medium"
          template="primary"
          typeStyle="rounded"
          className="forgor__pasword__button"
          type="submit"
          isDisabled={
            formForgotPassword.isSubmitting ||
            !formForgotPassword.isValid ||
            !formForgotPassword.dirty
          }
          isLoading={formForgotPassword.isSubmitting}
        >
          Cambiar <img src={Images.email} alt="" />
        </Button>
      </form>
      {popUp.isVisible &&
        (popUp.type === 'successful' ? (
          <PopupEmailSend
            email={formForgotPassword.values.email}
            closePopUp={showInfoPopUp}
          />
        ) : (
          <PopupErrorRecoverPassword
            email={formForgotPassword.values.email}
            closePopUp={showInfoPopUp}
          />
        ))}
    </>
  );
};
