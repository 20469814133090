import { IAppReducer, IDetailStep } from 'models';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getUserSubscriptions,
  Subscription,
} from 'shared/services/user-subscription';

interface UseSubscriptionsReturn {
  steps: IDetailStep[];
  navigate: ReturnType<typeof useNavigate>;
  user: IAppReducer['authReducer'];
  subscriptions: Subscription[];
  loading: boolean;
  error: boolean;
}

const STEPS: IDetailStep[] = [
  {
    description: 'ajustes',
    state: 'active',
    link: '/settings',
    permissionToNavigate: true,
  },
  {
    description: 'suscripciones',
    state: 'active',
    link: '/settings/subscriptions',
    permissionToNavigate: false,
  },
];

export const useSubscriptions = (): UseSubscriptionsReturn => {
  const navigate = useNavigate();
  const { authReducer: user } = useSelector((state: IAppReducer) => state);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const getSubscriptions = async () => {
      setLoading(true);
      setError(false);
      try {
        const subs = await getUserSubscriptions(user.userId);
        setSubscriptions(subs);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getSubscriptions();
  }, [user]);

  return {
    steps: STEPS,
    navigate,
    user,
    subscriptions,
    loading,
    error,
  };
};
