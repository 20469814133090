import cms from './cms';

const statusMap: { [key: string]: SubscriptionStatus } = {
  activa: 'successful',
};

type SubscriptionStatus = 'successful' | 'error';
type SubscriptionPeriodicity = 'ANUAL' | 'MENSUAL';

interface IProduct {
  product_id: {
    alias_title: string;
  };
}

interface ISubscriptionResponse {
  id: number;
  date_init: string;
  amount: number;
  status_subscription_id: {
    name: string;
  };
  periocity: {
    name: string;
  };
  productos_usuarios: IProduct[];
}

export interface Subscription {
  id: number;
  products: string[];
  price: number;
  date: string;
  typePeriodicity: SubscriptionPeriodicity;
  status: SubscriptionStatus;
}

const formatDate = (date_init: string): string => {
  const date = new Date(date_init);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const extractProducts = (productos_usuarios: IProduct[]): string[] => {
  return productos_usuarios.map((product) => product.product_id.alias_title);
};

const formatPeriodicity = (
  periodicityName: string
): SubscriptionPeriodicity => {
  const upperName = periodicityName.toUpperCase();
  if (upperName !== 'ANUAL' && upperName !== 'MENSUAL') {
    throw new Error(`Periodicidad inválida: ${periodicityName}`);
  }
  return upperName as SubscriptionPeriodicity;
};

const formatStatus = (statusName: string): SubscriptionStatus => {
  return statusMap[statusName.toLowerCase()] || 'error';
};

const formatSubscriptionResponse = (
  response: ISubscriptionResponse[]
): Subscription[] => {
  if (!Array.isArray(response)) {
    throw new Error('La respuesta debe ser un array');
  }

  return response.map((subscription) => {
    if (!subscription || typeof subscription !== 'object') {
      throw new Error('Elemento de suscripción inválido');
    }
    const {
      id,
      amount,
      date_init,
      status_subscription_id,
      periocity,
      productos_usuarios,
    } = subscription;

    if (
      !id ||
      !date_init ||
      !periocity?.name ||
      !status_subscription_id?.name
    ) {
      throw new Error('Datos de suscripción incompletos');
    }

    return {
      id,
      products: extractProducts(productos_usuarios),
      date: formatDate(date_init),
      typePeriodicity: formatPeriodicity(periocity.name),
      status: formatStatus(status_subscription_id.name),
      price: amount,
    };
  });
};

export async function getUserSubscriptions(
  userId: number
): Promise<Subscription[]> {
  try {
    const response = await cms.get(
      `/user-subscriptions/user-products/${userId}`
    );
    return formatSubscriptionResponse(response.data);
  } catch (error) {
    console.error(error);
    throw new Error('Failed to get subscriptions 🚨');
  }
}
