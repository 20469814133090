import { useMemo } from 'react';

export const useProductTitle = (products: string[]) => {
  return useMemo(() => {
    if (products.length === 1) {
      return products[0];
    } else if (products.length === 2) {
      return `${products[0]}, ${products[1]}`;
    } else if (products.length > 2) {
      return `${products[0]}, ${products[1]} y más...`;
    } else {
      return '';
    }
  }, [products]);
};
