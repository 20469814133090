import { IdataCheckoutAction } from 'pages/Checkout/store/state/checkout.state';
import { IdataCreateAccount } from 'pages/Create-account/store/state/create-account.state';
interface IAppReducerSaveState {
  authReducer: {
    userId: number;
    uid: string;
    name: string;
    email: string;
    phone: string;
    photoURL: string;
    accessToken: null;
    refreshToken: null;
    idToken: null;
    authenticatedUser: true;
  };
  branchReducer: {
    branch: string;
    country_id: string;
  };
  createAccountReducer: IdataCreateAccount;
  checkoutReducer: IdataCheckoutAction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editPlanReducer: { data: any[] };
}
export const hydrationMetaReducer = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: IAppReducerSaveState) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};
