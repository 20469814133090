import { Card, Chips, ItemStatus } from 'components';
import './Item-subscription.scss';
import { useProductTitle } from './hooks/Item-Subscription.hook';

interface ItemSubscriptionProps {
  products: string[];
  price: number;
  date: string;
  typePeriodicity: string;
  status: 'successful' | 'error';
}

const messageStatus = {
  successful: 'Activa',
  error: 'Inactiva',
};

export const ItemSubscription = ({
  products,
  price,
  date,
  typePeriodicity,
  status,
}: ItemSubscriptionProps) => {
  const productTitle = useProductTitle(products);
  return (
    <div className="item__sub__container">
      <Card>
        <div className="item__sub__content">
          <div className="item__sub__title">
            <span className="title--bold row">
              <span>{productTitle}</span>
            </span>
          </div>
          <div className="item__sub__detail">{`$${price} - ${date}`}</div>
          <div className="item__sub__status">
            <Chips label={typePeriodicity} />
            <ItemStatus
              status={status}
              message={messageStatus[status]}
            ></ItemStatus>
          </div>
        </div>
      </Card>
    </div>
  );
};
