import { Alert } from 'components/atoms/Alert/Alert';
import { Divider } from 'components/atoms/Divider/Divider';
import { Input } from 'components/atoms/Input/Input';
import { ButtonAuthProvider } from 'components/molecules/Button-auth-provider/Button-auth-provider';
import { AuthFromGeneric } from 'components/templates/Auth-from-generic/Auth-from-generic';
import { defaultFirebaseAuth } from 'config/firebase-config';
import { getIdToken, signInWithEmailAndPassword } from 'firebase/auth';
import { useFormik } from 'formik';
import { IAppReducer } from 'models/reducer.interface';
import { headerInformation } from 'pages/Auth/Constant/auth.constant';
import { resetCheckoutInformation } from 'pages/Checkout/store/actions/checkout.types';
import { resetCreateAccountInformation } from 'pages/Create-account/store/actions/create-account.types';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { googleLogin } from 'shared/services/login-google.service';
import { authUserGoogle, loginUser } from 'shared/services/users';
import { setStateError } from 'store/actions/app.types';
import { setBranch } from 'store/actions/branch.types';
import { setResetPassword, setUser } from '../store/actions/auth.types';
import './Login.scss';
import { IFormLogin, IFormLoginErros } from './models/Login.interface';
interface LocationState {
  redirect: string;
  email: string;
}

const Login = () => {
  const {
    authReducer: { isResetPassword, agentId },
  } = useSelector((state: IAppReducer) => state, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState<boolean>(isResetPassword);
  const location = useLocation();
  const locationState = location.state as LocationState;
  const params = new URLSearchParams(location.search);
  const emailParams = locationState?.email || (params.get('email') as string);
  useEffect(() => {
    dispatch(resetCheckoutInformation());
    dispatch(resetCreateAccountInformation());
  }, []);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        dispatch(setResetPassword(false));
        setShowAlert(false);
      }, 16000);
    }
  }, [showAlert]);
  const formLoginInitialValues: IFormLogin = {
    email: emailParams ?? '',
    password: '',
  };
  const formLoginValidate = (values: IFormLogin) => {
    const errors: IFormLoginErros = {};
    if (!values.email) {
      errors.email = 'Este campo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'No es un correo valido, debe tener @ y dominio';
    }
    if (!values.password) {
      errors.password = 'Este campo es requerido';
    } else if (values.password.length < 6) {
      errors.password = 'Este campo debe tener mínimo 6 caracteres';
    }
    return errors;
  };

  const formLogin = useFormik({
    initialValues: formLoginInitialValues,
    validate: formLoginValidate,
    onSubmit: async (values) => {
      await handleLoginWithEmailAndPassword(values.email, values.password);
      formLogin.setSubmitting(false);
    },
  });

  const handleGoogleLogin = async () => {
    try {
      const { data } = await googleLogin();
      const user = data?.user;
      const idToken = await getIdToken(user);
      const userAuth = await authUserGoogle(idToken);
      dispatch(
        setUser({
          ...user,
          accessToken: userAuth.jwt,
          name: userAuth.user.name,
          lastname: userAuth.user.lastname,
          userAuth: userAuth.user,
          companyId: userAuth.user.company.id,
          formToShow: userAuth.user.company?.formToShow || null,
        })
      );
      if (agentId) {
        window.location.reload();
      }
    } catch (error) {
      handleErrorPopUp();
    }
  };

  const setBranchDefault = async (branch: string) => {
    dispatch(setBranch(branch));
  };

  const handleLoginWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        defaultFirebaseAuth,
        email,
        password
      );
      const user = userCredential.user;
      const userAuth = await loginUser(user);

      formLogin.resetForm();
      dispatch(
        setUser({
          ...user,
          userId: userAuth.user.id,
          name: userAuth.user.name,
          lastname: userAuth.user.lastname,
          userAuth: userAuth.user,
          accessToken: userAuth.jwt,
          agentId: userAuth.user.agentId,
          companyId: userAuth.user.company?.id || null,
          formToShow: userAuth.user.company?.formToShow || null,
          user_type: userAuth.user.user_type?.name || null,
          role: userAuth.user.role ?? null,
        })
      );
      setBranchDefault(userAuth.user.country.acronym);
      if (locationState?.redirect) {
        navigate(locationState?.redirect);
      }
    } catch (error) {
      handleErrorPopUp();
    }
  };

  const handleErrorPopUp = () => {
    dispatch(setStateError(true));
  };

  return (
    <>
      {showAlert && (
        <Alert
          type="successful"
          onClick={() => {
            setShowAlert(false);
          }}
          title="¡CONTRASEÑA creada!"
          message="Ahora <strong>inicie sesión</strong> para completar <br />  el proceso."
        />
      )}
      <form onSubmit={formLogin.handleSubmit} noValidate>
        <AuthFromGeneric
          labelPrimaryAction="Ingresar"
          labelFromLink="Olvidé mi contraseña"
          labelSecondaryAction="Crear cuenta nueva"
          secondaryActionPath="/"
          headerInformation={headerInformation}
          isLoading={formLogin.isSubmitting}
          handleClick={formLogin.handleSubmit}
        >
          <Input
            label="Correo Electrónico"
            id="login-email"
            type="email"
            name="email"
            onChange={formLogin.handleChange}
            onBlur={formLogin.handleBlur}
            value={formLogin.values.email}
            isTouched={formLogin.touched.email}
            errorInput={formLogin.errors.email}
            errorLabel={formLogin.errors.email}
            isRequired={true}
            form={formLogin}
          />
          <Input
            label="Contraseña"
            id="login-password"
            type="password"
            name="password"
            onChange={formLogin.handleChange}
            onBlur={formLogin.handleBlur}
            value={formLogin.values.password}
            placeholder=""
            isTouched={formLogin.touched.password}
            errorInput={formLogin.errors.password}
            errorLabel={formLogin.errors.password}
            isRequired={true}
            form={formLogin}
          />
          {false && (
            <>
              <Divider
                classWrapper="from__login__divider"
                margin="notMargin"
              ></Divider>
              <ButtonAuthProvider
                actionLabel="Continuar con Google"
                type="google"
                handleLogin={handleGoogleLogin}
                styleInLine={{ marginBottom: '1rem' }}
              ></ButtonAuthProvider>
            </>
          )}
        </AuthFromGeneric>
      </form>
    </>
  );
};
export default Login;
