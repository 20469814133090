import { useRoutes } from 'react-router-dom';
import { PaymentsAndBilling } from '../pages/Payments-billing/Payments-billing';
import { Profile } from '../pages/Profile/Profile';
import { Properties } from '../pages/Properties/Properties';
import { ServicesHistory } from '../pages/Services-history/Service-history';
import { SettingsOptions } from '../pages/Settings-options/Settings-options';
import { Subscriptions } from '../pages/Subscriptions/Subscriptions';

export const SettingsRouter = () => {
  const routes = useRoutes([
    {
      children: [
        {
          path: '/profile',
          element: <Profile />,
        },
        {
          path: '/properties',
          element: <Properties />,
        },
        {
          path: '/payments',
          element: <PaymentsAndBilling />,
        },
        {
          path: '/subscriptions',
          element: <Subscriptions />,
        },
        {
          path: '/services-history',
          element: <ServicesHistory />,
        },
        {
          path: '/*',
          element: <SettingsOptions />,
        },
      ],
    },
  ]);
  return routes;
};
