import './User-Details.scss';

interface UserDetailsProps {
  name: string;
  email: string;
}

export const UserDetails = ({ name, email }: UserDetailsProps) => {
  return (
    <article className="user-details" aria-label="Detalles del usuario">
      <h1 className="user-details__name">{name}</h1>
      <p
        className="user-details__email"
        aria-label={`Correo electrónico: ${email}`}
      >
        {email}
      </p>
    </article>
  );
};
