import { Divider } from 'components/atoms/Divider/Divider';
import { ReactNode } from 'react';
import './Title-section.scss';
interface IPropsTitleSection {
  title: string;
  subtitle?: string;
  children?: ReactNode;
}

export const TitleSection = (props: IPropsTitleSection) => {
  return (
    <>
      <div className="container__subtitle__section">
        <div className="container__title">
          <h1 className="title__section">{props.title}</h1>
          {props.subtitle && (
            <p className="subtitle subtitle__section">{props.subtitle}</p>
          )}
        </div>
        {props.children}
      </div>
      <Divider margin="small"></Divider>
    </>
  );
};
