import { authAction, TypeActionsSignIn } from '../actions/auth.types';
import { initalStateAuth } from '../state/state';

export const authReducer = (state = initalStateAuth, action: authAction) => {
  switch (action.type) {
    case TypeActionsSignIn.login:
      return {
        ...state,
        userId: action.data.userId,
        uid: action.data.uid,
        name: action.data.name || action.data.displayName,
        lastname: action.data.lastname,
        email: action.data.email,
        phone: action.data.userAuth.phone || '',
        photoURL: action.data.photoURL || '',
        indicator: action.data.userAuth.phone_country_code || '',
        accessToken: action.data.accessToken,
        agentId: action.data.agentId || undefined,
        dateLogin: new Date(),
        agentAuthData: action.data.agentAuthData || undefined,
        companyId: action.data.companyId || undefined,
        plansId: action.data.plansId || undefined,
        formToShow: action.data.formToShow || undefined,
        user_type: action.data.user_type?.toLocaleLowerCase() || undefined,
        role: action.data.role ?? null,
      };
    case TypeActionsSignIn.updateUser:
      return {
        ...state,
        ...action.data,
      };
    case TypeActionsSignIn.authenticatedUser:
      return {
        ...state,
        authenticatedUser: action.data,
      };
    case TypeActionsSignIn.logout:
      return {
        ...initalStateAuth,
      };
    case TypeActionsSignIn.resetPassword:
      return {
        ...state,
        isResetPassword: action.data,
      };
    default:
      return state;
  }
};
