export const config = {
  firebase: {
    firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    firebaseRealDataBase: process.env.REACT_APP_FIREBASE_REAL_TIME_DB,
    firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    firebaseMessagingSenderId:
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  merakiApi: {
    url: process.env.REACT_APP_MERAKI_API,
    token: process.env.REACT_APP_MERAKI_TOKEN,
  },
  merakiCms: {
    url: process.env.REACT_APP_MERAKI_CMS,
    token: process.env.REACT_APP_MERAKI_CMS_TOKEN,
  },
  salesApi: {
    url: process.env.REACT_APP_API_SALES_URL,
  },
  connectWeb: {
    pr: 'https://connect.pr',
    cr: 'https://connect.cr',
    pty: 'https://www.connect.com.pa/',
    co: 'https://connect.com.co',
    mx: 'https://connect.pr',
  },
  accounts: {
    pr: {
      name: 'Connect Asistance Puerto Rico',
      id: process.env.REACT_APP_ACCOUNT_ID_PR,
    },
    co: {
      name: 'Connect Asistance Colombia',
      id: process.env.REACT_APP_ACCOUNT_ID_CO,
    },
    cr: {
      name: 'Connect Asistance CR',
      id: process.env.REACT_APP_ACCOUNT_ID_CR,
    },
    pty: {
      name: 'Connect Asistance Panama',
      id: process.env.REACT_APP_ACCOUNT_ID_PTY,
    },
    mx: {
      name: 'Connect Asistance México',
      id: process.env.REACT_APP_ACCOUNT_ID_MX,
    },
  },
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  stripe: {
    pr: process.env.REACT_APP_PK_STRIPE,
    cr: process.env.REACT_APP_PK_STRIPE_CR,
    co: process.env.REACT_APP_PK_STRIPE_CO,
  },
  dlocal: {
    cr: process.env.REACT_APP_DLOCAL_SMART_FIELDS_KEY_CR as string,
    co: process.env.REACT_APP_DLOCAL_SMART_FIELDS_KEY_CO as string,
  },
  default_plans: {
    pr: process.env.REACT_APP_DEFAULT_PLANS_ID_PR,
    cr: process.env.REACT_APP_DEFAULT_PLANS_ID_CR,
    pa: process.env.REACT_APP_DEFAULT_PLANS_ID_PA,
    co: process.env.REACT_APP_DEFAULT_PLANS_ID_CO,
    free: process.env.REACT_APP_PLAN_FREEMIUN_ID,
    addon: process.env.REACT_APP_PLAN_ADDON_ID,
  },
  defaultBranch: process.env.REACT_APP_DEFAULT_BRANCH,
  segment: {
    key: process.env.REACT_APP_SEGMENT_KEY,
  },
  appiKey: process.env.REACT_APP_APPI_KEY,
  hotjarId: process.env.REACT_APP_HOTJAR_ID,
  timer: {
    timeOfInactivity: process.env.REACT_APP_TIME_OF_INACTIVITY,
    timeOfSession: process.env.REACT_APP_TIME_OF_SESSION,
  },
  aesKey: process.env.REACT_APP_KEY_AES,
  googleTagManagerId: process.env.REACT_GTM_ID as string,
  ruedazUrl: process.env.REACT_APP_RUEDAZ_URL as string,
  zapier: {
    webhookRuedaz: process.env.REACT_APP_ZAPIER_WEBHOOK_URL as string,
  },
  pendo: {
    pendoApiKey: process.env.REACT_APP_PENDO_API_KEY,
    pendoAccountId: process.env.REACT_APP_PENDO_ACCOUNT_ID,
  },
};
