import { IOptionSelect } from 'components/atoms/Select/Select';
import { defaultFirebaseAuth } from 'config/firebase-config';
import { signInWithCustomToken } from 'firebase/auth';
import { useFormik } from 'formik';
import * as Analytics from 'helpers/segment.helper';
import * as zipCode from 'helpers/zipCodes';
import { IAppReducer } from 'models/reducer.interface';
import { setCreateAccountInformation } from 'pages/Create-account/store/actions/create-account.types';
import { setUser } from 'pages/sing-in/store/actions/auth.types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AESEncryptDecryptService } from 'shared/services/aes-encrypt-decrypt.service';
import { registerUser } from 'shared/services/users';
import { setStateError } from 'store/actions/app.types';
import { setBranch } from 'store/actions/branch.types';
import * as utils from 'utils/branch.utils';
import * as personalInformationConstants from '../constants/Personal-information.constants';
import {
  CountryCode,
  IFormErrorsPersonalInformation,
  IFormPersonalInformation,
} from '../models/Personal-information.interface';

export const UsePersonalInformationValidation = () => {
  const textEncrypt = new AESEncryptDecryptService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    createAccountReducer,
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state, shallowEqual);
  const [countryAdministrativeDivision, setCountryAdministrativeDivision] =
    useState<{
      provinces: IOptionSelect[];
      districts: IOptionSelect[];
      cantons: IOptionSelect[];
    }>({ provinces: [], districts: [], cantons: [] });

  const getOptionsProvinces = async (country = branch) => {
    try {
      let provincesResponse = [];
      if (country === 'cr') {
        provincesResponse = await zipCode.getProvincias(CountryCode.cr);
      } else if (country === 'co') {
        provincesResponse = await zipCode.getDeparments();
      } else if (country === 'pty') {
        provincesResponse = await zipCode.getProvincias(CountryCode.pty);
      }
      const options: IOptionSelect[] = mappingOptions(provincesResponse);
      setCountryAdministrativeDivision({
        provinces: [...options],
        districts: [],
        cantons: [],
      });
      return options;
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    (async () => getOptionsProvinces())();
  }, [branch]);

  const mappingOptions = (
    array: { id: number; name: string }[]
  ): IOptionSelect[] => {
    return array.map((item: { id: number; name: string }) => {
      return {
        value: item.id.toString(),
        description: item.name,
      };
    }, []);
  };

  const formPersonalInformationInitialValues: IFormPersonalInformation = {
    name: createAccountReducer ? createAccountReducer.name : '',
    lastname: createAccountReducer ? createAccountReducer.lastname : '',
    indicator: utils.getPhoneIndicator(branch),
    phone: createAccountReducer ? createAccountReducer.phone : '',
    country_id: utils.getCountry(branch).toString(),

    acceptTerms: createAccountReducer
      ? createAccountReducer.acceptTerms
      : false,
    province: '',
    district: '',
    canton: '',
    documentNumber: '',
    zipCode: '',
  };

  const formPersonalInformationValidate = (
    values: IFormPersonalInformation
  ) => {
    const errors: IFormErrorsPersonalInformation = {};
    const phoneLength = utils.lengthPhone(branch);
    if (!values.name) {
      errors.name = 'Este campo es requerido';
    }
    if (!values.lastname) {
      errors.lastname = 'Este campo es requerido';
    }

    if (!values.country_id) {
      errors.country_id = 'Este campo es requerido';
    }
    if (branch === 'pr') {
      if (!values.zipCode) {
        errors.zipCode = 'Este campo es requerido';
      }
    }
    if (!['pr', 'pty', 'mx'].includes(branch)) {
      if (!values.province) {
        errors.province = 'Este campo es requerido';
      }
    }

    if (branch === 'cr') {
      if (!values.canton) {
        errors.canton = 'Este campo es requerido';
      }
    }
    if (['cr', 'co'].includes(branch)) {
      if (!values.documentNumber) {
        errors.documentNumber = 'Este campo es requerido';
      }
    }
    if (!['pty', 'mx'].includes(branch)) {
      if (!values.district) {
        errors.district = 'Este campo es requerido';
      }
    }

    if (!values.acceptTerms) {
      errors.acceptTerms = 'Este campo es requerido';
    }
    if (!values.indicator) {
      errors.indicator = 'Este campo es requerido';
    }
    if (!values.phone) {
      errors.phone = 'Este campo es requerido';
    } else if (values.phone.length < phoneLength.min) {
      errors.phone = 'Teléfono muy corto';
    } else if (values.phone.length > phoneLength.max) {
      errors.phone = 'Teléfono muy largo';
    }
    return errors;
  };

  const formPersonalInformation = useFormik({
    initialValues: formPersonalInformationInitialValues,
    validate: formPersonalInformationValidate,
    onSubmit: async (values) => {
      try {
        const customRegister = await registerUser({
          name: values.name,
          lastname: values.lastname,
          email: createAccountReducer.email.toLocaleLowerCase(),
          phone: values.phone,
          country_id: values.country_id,
          zipCode: values.zipCode,
          address: '',
          password: textEncrypt.decrypt(createAccountReducer.password),
          indicator: values.indicator,
          countryAcronym: branch,
        });
        dispatch(
          setCreateAccountInformation({
            created: true,
            step: 1,
            auth_id: customRegister.user.auth_id,
            country_id: values.country_id as string,
          })
        );
        await handleRegisterWithCustomToken(customRegister.customToken);
        dispatch(
          setUser({
            userId: customRegister.user.id,
            uid: customRegister.user.auth_id,
            name: values.name,
            lastname: values.lastname,
            email: createAccountReducer.email.toLocaleLowerCase(),
            accessToken: customRegister.jwt,
            companyId: customRegister.user.company?.id || null,
            formToShow: customRegister.user.company?.formToShow || null,
            user_type: customRegister.user.user_type?.name || null,
            userAuth: {
              phone: values.phone,
              country: {
                countryCode: values.indicator,
              },
            },
          })
        );
        const dataCheckoutStepCompleted = {
          step: 2,
        };
        Analytics.checkoutStepCompleted(dataCheckoutStepCompleted);
        const dataIdentifyUser = {
          name: values.name,
          lastName: values.lastname,
          email: createAccountReducer.email.toLocaleLowerCase(),
          phone: values.phone,
          plan: createAccountReducer.plan_id,
          address: {
            country: personalInformationConstants.countryOptions.find(
              (p) => p.value == Number(values.country_id)
            )?.description as string,
            postalCode: values.zipCode,
            city: countryAdministrativeDivision.districts.find(
              (p) => p.value == values.district
            )?.description as string,
            state: countryAdministrativeDivision.provinces.find(
              (p) => p.value == values.province
            )?.description as string,
          },
        };
        Analytics.identifyUser(dataIdentifyUser);
        if (createAccountReducer.plan_id) {
          navigate('/create-account/activate-benefits');
        } else {
          navigate('/settings', {
            state: {
              infoPopUp: {
                showPopUp: true,
                email: createAccountReducer.email.toLocaleLowerCase(),
                name: createAccountReducer.name,
              },
            },
          });
        }
      } catch (error) {
        dispatch(setStateError(true));
      } finally {
        formPersonalInformation.setSubmitting(false);
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFocus = (event: React.FocusEvent<any>) => {
    const { name, value } = event.target;
    if (value) {
      dispatch(setCreateAccountInformation({ [name]: value }));
    }
    formPersonalInformation.handleBlur(event);
  };

  const handleRegisterWithCustomToken = async (customToken: string) => {
    const userCredential = await signInWithCustomToken(
      defaultFirebaseAuth,
      customToken
    );
    return userCredential.user;
  };
  const handleChangeCheckTerms = (event: boolean | undefined) => {
    formPersonalInformation.setFieldValue('acceptTerms', !event);
    dispatch(setCreateAccountInformation({ acceptTerms: !event }));
  };
  const handleOptionCountry = (event: number | string) => {
    const countryValue = { ...personalInformationConstants.countryValue }[
      event
    ] as string;
    if (countryValue !== branch) {
      const indicator = utils.getPhoneIndicator(countryValue);
      dispatch(setBranch(countryValue));
      let defaultZipCode = '';
      if ([3, 5].includes(event as number)) {
        if (event === 3) {
          defaultZipCode = '07196';
        } else {
          defaultZipCode = '11000';
        }
      }
      const values = {
        ...formPersonalInformation.values,
        country_id: event.toString(),
        province: '',
        canton: '',
        district: '',
        zipCode: defaultZipCode,
        indicator,
        id: '',
      };
      formPersonalInformation.setValues(values);
      dispatch(
        setCreateAccountInformation({
          indicator: indicator,
          country_id: event as string,
        })
      );
    }
  };
  const handleOptionProvince = async (
    event: number | string,
    country = branch
  ) => {
    let options: IOptionSelect[] = [
      {
        description: '',
        value: '',
      },
    ];
    if (['cr', 'pty'].includes(country)) {
      options = await handleOptionProvinceCostaRica(event);
    } else if (country === 'co') {
      options = await handleOptionMunicipalitiesColombia(event);
    }
    return options;
  };
  const handleOptionProvinceCostaRica = async (event: number | string) => {
    const values = {
      ...formPersonalInformation.values,
      province: event as string,
      canton: '',
      district: '',
      zipCode: '',
    };
    formPersonalInformation.setValues(values);
    const cantonsResponse = await zipCode.getCantons('CRC', event as string);
    const options: IOptionSelect[] = mappingOptions(cantonsResponse);
    setCountryAdministrativeDivision({
      ...countryAdministrativeDivision,
      cantons: [...options],
      districts: [],
    });
    return options;
  };
  const handleOptionMunicipalitiesColombia = async (event: number | string) => {
    const values = {
      ...formPersonalInformation.values,
      province: event as string,
      canton: '',
      district: '',
      zipCode: '',
    };
    formPersonalInformation.setValues(values);
    const responseMunicipalities = await zipCode.getMunicipalities(
      event as string
    );
    const options: IOptionSelect[] = mappingOptions(responseMunicipalities);
    setCountryAdministrativeDivision({
      ...countryAdministrativeDivision,
      cantons: [],
      districts: [...options],
    });
    return options;
  };
  const handleOptionCanton = async (
    event: number | string,
    country = branch
  ) => {
    const values = {
      ...formPersonalInformation.values,
      canton: event as string,
      district: '',
      zipCode: '',
    };
    formPersonalInformation.setValues(values);
    const districtsResponse = await zipCode.getDistricts(
      country === 'cr' ? CountryCode.cr : CountryCode.pty,
      formPersonalInformation.values.province,
      event as string
    );
    const options: IOptionSelect[] = mappingOptions(districtsResponse);
    setCountryAdministrativeDivision({
      ...countryAdministrativeDivision,
      districts: [...options],
    });
    return options;
  };

  const handleIndicatorCountry = (event: number | string) => {
    formPersonalInformation.setFieldValue('indicator', event);
  };
  const handleOptionDistrict = (event: number | string) => {
    formPersonalInformation.setFieldValue('district', event);
    handleOptionZipCode(event);
  };
  const handleOptionZipCode = (event: number | string) => {
    formPersonalInformation.setFieldValue('zipCode', event);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getZipCodeInfo = async (event: any) => {
    const values = {
      ...formPersonalInformation.values,
      province: '',
      canton: '',
      district: '',
    };
    formPersonalInformation.setValues(values);
    try {
      formPersonalInformation.handleChange(event);
      const { value } = event.target;
      if (value.length >= 5) {
        const response = await zipCode.getZipCodeInfoPuertoRico(value);
        setCountryAdministrativeDivision({
          ...countryAdministrativeDivision,
          districts: [
            {
              value,
              description: response.places[0]['place name'],
            },
          ],
        });
        handleOptionDistrict(value);
      }
    } catch (error) {
      formPersonalInformation.setFieldError('zipCode', 'Código incorrecto');
      setCountryAdministrativeDivision({
        ...countryAdministrativeDivision,
        districts: [],
        cantons: [],
      });
    }
  };

  return {
    formPersonalInformation,
    createAccountReducer,
    handleFocus,
    handleIndicatorCountry,
    countryAdministrativeDivision,
    handleOptionCountry,
    handleOptionCanton,
    handleOptionDistrict,
    handleOptionProvince,
    getZipCodeInfo,
    handleChangeCheckTerms,
  };
};
